
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// 文字が多かった場合でもレスポンシブに対応できるように
h1{
     word-wrap: break-word;
     -webkit-hyphens: auto;
     -moz-hyphens: auto;
     -ms-hyphens: auto;
     -o-hyphens: auto;
     hyphens: auto;
}

// 予約画面
.table>tbody>tr>td.available {
    cursor: pointer;
    &:hover {
        background-color: #d9edf7;
    }
}
.time_selected {
    background-color: #d9edf7;
}

// 特別スケジュール設定画面
.datepicker{
    &.datepicker-inline, &.datepicker-inline .table-condensed {
        width: 100%;
        height: 500px;
    }
    .edit {
        background-color: #67b168;
    }
}
